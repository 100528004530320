function Members() {
  return (
    <div className='container'>
      <hr />
      <h3 className='centered'>Η μπάντα</h3>
      <h5 className='centered'>
        <center>
          Δημήτρης Βαλιώτης ~ κρουστά
          <br />
          Λένα Καραγιαννίδου ~ ακορντεόν
          <br />
          Παντελής Μπατής ~ κιθάρα
          <br />
          Δημήτρης Χαριτάτος ~ φωνή, μπάσο
          <br />
          Δημήτρης Ψαθάς ~ φωνή, βιολί
        </center>
      </h5>
      <hr />
    </div>
  );
}

export default Members;
