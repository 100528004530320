/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { Tooltip } from "react-tooltip";
import "../App.css";
import InstaQR from "./InstaQr";
import logo from "../images/logo.png";

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      bar: {
        position: "fixed",
        top: "0",
        left: "0",
        width: "100%",
        height: "72px",
        zIndex: "20",
      },
      hideLinks: false,
      isPortrait: window.matchMedia("(orientation: portrait)").matches,
    };
  }

  componentDidMount() {
    this.handleOrientationChange();
    this.handleResize();
    window.addEventListener("orientationchange", this.handleOrientationChange);
    window.addEventListener("resize", this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener(
      "orientationchange",
      this.handleOrientationChange
    );
    window.removeEventListener("resize", this.handleResize);
  }

  handleOrientationChange = () => {
    const isPortrait = window.matchMedia("(orientation: portrait)").matches;
    this.setState({ hideLinks: isPortrait, isPortrait: isPortrait });
  };

  handleResize = () => {
    const isSmallWidth = window.innerWidth < 1080;
    this.setState({ hideLinks: isSmallWidth });
  };

  render() {
    const { hideLinks, bar, isPortrait } = this.state;

    return (
      <nav
        className='navbar navbar-dark bg-dark'
        style={bar}
        ref={this.headerRef}
      >
        <div className='container-fluid d-flex justify-content-between align-items-center'>
          <a className='navbar-brand' href='#'>
            <img
              src={logo}
              alt='Logo'
              width='72'
              height='48'
              className='d-inline-block align-text-center'
            />
          </a>

          <ul
            className={`nav ${
              hideLinks ? "d-none" : "d-flex"
            } flex-column-reverse flex-md-row`}
            style={{
              position: "absolute",
              left: "50%",
              transform: "translateX(-50%)",
            }}
          >
            <li>
              <a
                className='nav-link'
                onClick={() => this.props.scrollToSection("members")}
              >
                Μέλη
              </a>
            </li>
            <li>
              <a
                className='nav-link'
                onClick={() => this.props.scrollToSection("futureConcerts")}
              >
                Εμφανίσεις
              </a>
            </li>
            <li>
              <a
                className='nav-link'
                onClick={() => this.props.scrollToSection("interviews")}
              >
                Συνεντεύξεις
              </a>
            </li>
            <li>
              <a
                className='nav-link'
                onClick={() => this.props.scrollToSection("guests")}
              >
                Συμμετοχές
              </a>
            </li>
            <li>
              <a
                className='nav-link'
                onClick={() => this.props.scrollToSection("allVideos")}
              >
                Βίντεο
              </a>
            </li>
          </ul>

          <ul className='nav justify-content-end d-flex align-items-center'>
            <link
              rel='stylesheet'
              href='https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.2.1/css/all.min.css'
            />
            <li>
              <a
                href='https://open.spotify.com/artist/1cLiGxex6Grx8Er0K80qph'
                className='fab fa-spotify nav-link'
                data-tooltip-id='spotify-tooltip'
                target='_blank'
                rel='noopener noreferrer'
              />
              {!isPortrait && (
                <Tooltip id='spotify-tooltip'>Follow us on Spotify</Tooltip>
              )}
            </li>
            <li>
              <a
                href='https://www.youtube.com/channel/UCamLMP0GNpErhC6UuSmmM-w'
                className='fab fa-youtube nav-link'
                data-tooltip-id='youtube-tooltip'
                target='_blank'
                rel='noopener noreferrer'
              />
              {!isPortrait && (
                <Tooltip id='youtube-tooltip'>
                  Subscribe to our YouTube channel
                </Tooltip>
              )}
            </li>
            <li>
              <a
                href='https://www.tiktok.com/@carmen_venti'
                className='fab fa-tiktok nav-link'
                data-tooltip-id='tiktok-tooltip'
                target='_blank'
                rel='noopener noreferrer'
              />
              {!isPortrait && (
                <Tooltip id='tiktok-tooltip'>Follow us on TikTok</Tooltip>
              )}
            </li>
            <li>
              <a
                href='https://www.facebook.com/profile.php?id=100087147184867'
                className='fab fa-facebook nav-link'
                data-tooltip-id='facebook-tooltip'
                target='_blank'
                rel='noopener noreferrer'
              />
              {!isPortrait && (
                <Tooltip id='facebook-tooltip'>Like our Facebook page</Tooltip>
              )}
            </li>
            <li>
              <InstaQR />
            </li>
            <li>
              <a
                href='mailto:carmenventi4@gmail.com'
                className='fab fa-at nav-link'
                data-tooltip-id='email-tooltip'
              />
              {!isPortrait && (
                <Tooltip id='email-tooltip'>Send us an email</Tooltip>
              )}
            </li>
          </ul>
        </div>
      </nav>
    );
  }
}

export default Header;
