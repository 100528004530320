import React, { useState, useEffect } from "react";
import Video from "./Video";

const Interview = ({ interview }) => {
  if (interview.type === "video") {
    return (
      <>
        <h3 className='centered'>{interview.title}</h3>
        <div className='centered'>
          <Video video={interview.video} />
        </div>
        <hr className='container' />
      </>
    );
  }

  if (interview.type === "image") {
    return (
      <>
        <h3 className='centered'>{interview.title}</h3>
        <div className='container centered'>
          <img
            src={interview.image.src}
            alt={interview.image.alt}
            width={interview.image.width}
          />
        </div>
        <hr className='container' />
      </>
    );
  }

  if (interview.type === "link") {
    return (
      <>
        <h3 className='centered'>
          <a
            href={interview.link.href}
            target='_blank'
            rel='noopener noreferrer'
          >
            {interview.link.text}
          </a>
        </h3>
        <hr className='container' />
      </>
    );
  }

  return null;
};

function Interviews() {
  const [interviews, setInterviews] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetch(`/interviews.json?timestamp=${new Date().getTime()}`)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        return response.json();
      })
      .then((data) => {
        setInterviews(data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error loading interviews:", error);
        setLoading(false);
      });
  }, []);

  if (loading) {
    return <p>Loading...</p>;
  }

  return (
    <div className='container'>
      <h2 className='centered headerRad'>Συνεντεύξεις</h2>
      {interviews.map((interview) => (
        <Interview key={interview.id} interview={interview} />
      ))}
    </div>
  );
}

export default Interviews;
