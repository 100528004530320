import groupPhoto from "../images/group_photo.jpg";

function Description() {
  return (
    <>
      <div className='container, centered' style={{ marginTop: "72px" }}>
        <img src={groupPhoto} alt='Group' width='50%' />
      </div>
      <br />
      <h3 className='centered'>Carmen Venti ~ Το Τραγούδι του Ανέμου</h3>
      <p className='container' style={{ maxWidth: "64%", textAlign: "center" }}>
        <i>
          <center>
            Σε ένα νοερό ταξίδι στο παρελθόν, οι μουσικοί του σχήματος σταματούν
            σε διάφορα λιμάνια της Μαύρης Θάλασσας και της Μεσογείου και
            ζωντανεύουν τραγούδια των σαλονιών και των καταγωγίων, των καμπαρέ
            και των γιορτών, από την Οδησσό ως την Σεβίλλη και από την Νάπολι ως
            την Αλεξάνδρεια και τον Πειραιά. Κοντά στο ύφος της εποχής, αλλά και
            με νέα στοιχεία, οι Carmen Venti διηγούνται μουσικά μια συναρπαστική
            ιστορία, πότε χαρούμενη και πότε λυπημένη, όπως η ίδια η ζωή. Με
            καινούργια τραγούδια, συνθέσεις της μπάντας, συνεχίζουν μουσικά το
            νήμα μιας από τις πιο έντονες και ενδιαφέρουσες περιόδους του εγγύς
            παρελθόντος.
          </center>
        </i>
      </p>
    </>
  );
}

export default Description;
